import React from "react";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../../components/Layout";
import { SectionInnerContainer } from "../../templates/index-page";
import OuterContainer from "../../components/OuterContainer";

const InnerContainer = styled(SectionInnerContainer)`
  text-align: left;
`;

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <Helmet title={`Tags | ${title}`} />
    <OuterContainer>
      <InnerContainer>
        <section className="section">
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: "6rem" }}
              >
                <h1 className="title is-size-2 is-bold-light">Tagger</h1>
                <ul className="taglist">
                  {group.map((tag) => (
                    <li key={tag.fieldValue}>
                      <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                        {tag.fieldValue} ({tag.totalCount})
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </InnerContainer>
    </OuterContainer>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQueryNO {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
